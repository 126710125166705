import React from "react";
import Layout from "../../Layout";
import "./style.scss";

// import SEO from "../../seo";

export default function Annexure9() {
  return (
    <Layout>
      <div className="pdfview">
        <h1 className="heading">
          Annexure-9 - Fee Structure Session - 2023-2024
        </h1>

        <div
          type="application/pdf"
          width="100%"
          height="100%"
          className="pdfview__container"
        >
          <object
            type="application/pdf"
            width="100%"
            height="100%"
            className=""
            data="https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-9-FEE-STRUCTURE-SESSION-2023-2024.pdf"
          ></object>
        </div>
      </div>
    </Layout>
  );
}
